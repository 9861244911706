const texts = {
  en: {
    keepOnScreen: 'KEEP ON SCREEN',
    helpTitle: 'HELP'
  },
  es: {
    keepOnScreen: 'MANTENER EN PANTALLA',
    helpTitle: 'AYUDA'
  },
  es_pe: {
    keepOnScreen: 'MANTENER EN PANTALLA',
    helpTitle: 'AYUDA'
  },
  pt: {
    keepOnScreen: 'MANTER NA TELA',
    helpTitle: 'AJUDA'
  },
  pt_br: {
    keepOnScreen: 'MANTER NA TELA',
    helpTitle: 'AJUDA'
  },
  it: {
    keepOnScreen: 'Mantieni sullo schermo',
    helpTitle: 'Aiuto'
  },
  fr: {
    keepOnScreen: "Garder à l'écran",
    helpTitle: 'Aide'
  },
  ru: {
    keepOnScreen: 'Оставить на экране',
    helpTitle: 'Помощь'
  },
  ja: {
    keepOnScreen: '画面に表示を維持',
    helpTitle: 'ヘルプ'
  },
  ko: {
    keepOnScreen: '화면에 유지',
    helpTitle: '도움말'
  },
  vi: {
    keepOnScreen: 'Giữ trên màn hình',
    helpTitle: 'Trợ giúp'
  },
  zh: {
    keepOnScreen: '保持在屏幕上',
    helpTitle: '帮助'
  },
  zh_hant: {
    keepOnScreen: '保持在屏幕上',
    helpTitle: '幫助'
  },
  tr: {
    keepOnScreen: 'Ekranda Tut',
    helpTitle: 'Yardım'
  },
  th: {
    keepOnScreen: 'คงไว้บนหน้าจอ',
    helpTitle: 'ช่วยเหลือ'
  },
  hi: {
    keepOnScreen: 'स्क्रीन पर रखें',
    helpTitle: 'सहायता'
  },
  bg: {
    keepOnScreen: 'Задръж на екрана',
    helpTitle: 'Помощ'
  },
  cs: {
    keepOnScreen: 'Nechat na obrazovce',
    helpTitle: 'Nápověda'
  },
  da: {
    keepOnScreen: 'Hold på skærmen',
    helpTitle: 'Hjælp'
  },
  de: {
    keepOnScreen: 'Auf dem Bildschirm behalten',
    helpTitle: 'Hilfe'
  },
  el: {
    keepOnScreen: 'Διατήρηση στην οθόνη',
    helpTitle: 'Βοήθεια'
  },
  et: {
    keepOnScreen: 'Hoida ekraanil',
    helpTitle: 'Abi'
  },
  fi: {
    keepOnScreen: 'Pidä näytöllä',
    helpTitle: 'Apua'
  },
  hu: {
    keepOnScreen: 'Tartsa a képernyőn',
    helpTitle: 'Segítség'
  },
  id: {
    keepOnScreen: 'Tetap di layar',
    helpTitle: 'Bantuan'
  },
  is: {
    keepOnScreen: 'Halda á skjánum',
    helpTitle: 'Hjálp'
  },
  lt: {
    keepOnScreen: 'Palikti ekrane',
    helpTitle: 'Pagalba'
  },
  lv: {
    keepOnScreen: 'Uzturēt ekrānā',
    helpTitle: 'Palīdzība'
  },
  ms: {
    keepOnScreen: 'Tetap pada skrin',
    helpTitle: 'Bantu'
  },
  nl: {
    keepOnScreen: 'Houden op het scherm',
    helpTitle: 'Help'
  },
  no: {
    keepOnScreen: 'Hold på skjermen',
    helpTitle: 'Hjelp'
  },
  pl: {
    keepOnScreen: 'Pozostaw na ekranie',
    helpTitle: 'Pomoc'
  },
  ro: {
    keepOnScreen: 'Păstrați pe ecran',
    helpTitle: 'Ajutor'
  },
  sk: {
    keepOnScreen: 'Zachovať na obrazovke',
    helpTitle: 'Pomoc'
  },
  sr: {
    keepOnScreen: 'Задржи на екрану',
    helpTitle: 'Помоћ'
  },
  sv: {
    keepOnScreen: 'Håll kvar på skärmen',
    helpTitle: 'Hjälp'
  }
};

export default texts;