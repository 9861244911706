<template>
  <dialog class="helpDialog" :class="dialogStyle">
    <div class="dialogHeader">{{ helpTitle }}</div>
    <div class="dialogContent">{{ helpText }}</div>
  </dialog>
</template>

<script>
import { useStore } from 'vuex';
import { ref, watch, onMounted, computed } from '@vue/runtime-core';
import texts from '../assets/locales';
export default {
  name: 'HelpDialog',
  emits: ['showHelp'],
  props: {
    isLandscape: Boolean,
    locale: String,
    promoToolActive: String
  },
  setup(props) {
    const store = useStore();
    const dialogStyle = ref('');
    const helpTitle = ref('');
    const helpText = computed(() => store.state.helpTexts.find((text) => text.appName === props.promoToolActive)?.helpText || '');

    onMounted(() => {
      dialogStyle.value = props.isLandscape ? 'dialogLandscape' : 'dialogPortrait';
    })

    watch(() => props.isLandscape, (landscape) => {
      dialogStyle.value = landscape ? 'dialogLandscape' : 'dialogPortrait';
    })

    watch(() => props.locale, (locale) => {
      helpTitle.value = texts[locale]? texts[locale].helpTitle : texts.en.helpTitle;
    })

    return { dialogStyle, helpTitle, helpText } 
  }
}
</script>

<style scoped>
.helpDialog {
  display: block;
  position: absolute;
  padding: 0;
  border: none;
  z-index: 5;
  width: 100%;
  -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
  user-select: none;
  touch-action: none;
}

.dialogLandscape {
  top: 9%;
  height: 91%;
  background: var(--blackColor);
}

.dialogPortrait {
  top: 20%;
  height: 95vh;
  background: var(--greyColor);
}

.dialogHeader {
  height: 6%;
  color: #FF0E6D;
  font-family: 'Ubuntu-Medium';
  font-size: 4vh;
  padding: 5%;
}

.dialogContent {
  width: 88%;
  height: 86%;
  color: #C8C8C8;
  padding: 0 5%;
  font-family: 'Ubuntu-Regular';
  font-size: 2.4vh;
  white-space: pre-line;
  text-align: start;
  overflow-y: auto;
  scrollbar-color: #FF0E6D var(--greyLighColor);
  scrollbar-width: thin;
}

.dialogContent::-webkit-scrollbar {
  width: 8px;
}

.dialogContent::-webkit-scrollbar-track {
  background: var(--greyLighColor);
}

.dialogContent::-webkit-scrollbar-thumb {
  background: #FF0E6D;
}
</style>