<template>
  <div class="keepOnScreenContainer">
    <input type="checkbox" @click="$emit('handleKeepOnScreen')" :checked="isChecked" />
    <span class="keepOnScreenTitle"> {{ keepOnScreenText }} </span>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { watch, ref, computed } from '@vue/runtime-core';
import texts from '../assets/locales';
export default {
  name: 'KeepBar',
  emits: ['handleKeepOnScreen'],
  props: { locale: String },
  setup(props) {
    const store = useStore();
    const keepOnScreenText = ref('');
    const isChecked = computed(() => store.state.keepOnScreen);

    watch(() => props.locale, (locale) => {
      keepOnScreenText.value = texts[locale]? texts[locale].keepOnScreen : texts.en.keepOnScreen;
    });

    return { keepOnScreenText, isChecked }
  }
}
</script>

<style>
.keepOnScreenContainer {
  margin-top: auto;
  background-color: var(--greyColor);
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  height: 6%;
  user-select: none;
  touch-action: none;
}
.keepOnScreenTitle {
  padding-left: 10px;
  font-size: 1.65vh;
  font-family: "Ubuntu-Medium";
  color: #EEEEEE;
}
input[type="checkbox"] {
  appearance: none;
  background-color: var(--blackColor) !important;
  margin: 0;
  width: 3.4vh;
  height: 3.4vh;
  border: 0.2em solid var(--greyLighColor);
  border-radius: 0.2em;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  pointer-events: auto;
}
input[type="checkbox"]:checked {
  border: 0.2em solid #474747;
}
input[type="checkbox"]:checked:before {
  content: "●";
  font-size: 2.5vh;
  color: var(--redColor);
}
input[type="checkbox"]:hover {
  border-color: var(--redColor) !important;
}
</style>